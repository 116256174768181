@import "../global.scss";

.projects-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background-color: #425669;
  overflow: auto;
  @include cell {
    justify-content: center;
    flex-flow: wrap;
    .container {
      width: 50%;
    }
  }
  @include tablet {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    .container {
      width: 50%;
    }
  }
  @include tablet-landscape {
    display: block;
    justify-content: center;
    flex-flow: wrap;
    .container {
      width: 50%;
    }
  }
  @include desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  @include large-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .container {
    // width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    margin: 20px;

    .card {
      min-height: 80vh;
      width: 30rem;
      box-shadow: 0 20px 20px rgba($color: #000000, $alpha: 0.2),
        0px 0px 50px rgba($color: #000000, $alpha: 0.2);
      border-radius: 30px;
      padding: 0rem 5rem;

      .hero {
        min-height: 35vh;
        display: flex;
        justify-content: center;
        align-items: center;

        iframe {
          width: 30rem;
          z-index: 2;
          @include cell {
            width: 20rem;
          }
        }
        img {
          width: 30rem;
          z-index: 2;
          @include cell {
            width: 20rem;
          }
        }

        video {
          width: 30rem;
          z-index: 2;
          @include cell {
            width: 20rem;
          }
        }
      }
      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        .title {
          font-size: 3rem;
          color: #f0d370;
          @include cell {
            font-size: 2rem;
          }
        }

        p {
          font-size: 1rem;
          padding: 1rem 0rem;
          color: #f0d370;
          font-weight: lighter;
          @include cell {
            font-size: 0.75rem;
          }
        }
        .tech-stack {
          font-size: 1.5rem;
          color: #f0d370;
          @include cell {
            font-size: 1.5rem;
          }

          p {
            font-size: 1rem;
            color: #f0d370;
            @include cell {
              font-size: 0.75rem;
            }
          }
        }
        .github {
          padding: 1rem 2rem;
          background-color: #f0d370;
          color: #425669;
          box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.2);
          cursor: pointer;
          font-weight: bold;
          border-radius: 30px;

          a {
            text-decoration: none;
            color: #425669;
          }
          .github:hover {
            rotate: 10deg;
          }
        }
      }
    }
  }
}
