* {
    margin: 0;
    padding: 0;
    border: none;
    
}
.App {
    height: 100vh;
    

    
    .sections{
        overflow-x: hidden;
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
        

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
            

        }
    }
}
