@import "../global.scss";

.menu {
    width: 300px;
    height: 100vh;
    background-color: #2f3e4b;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;

    &.active {
        right: 0;
    }
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        color: $yellowColor;

        li{
            margin-bottom: 20px;
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
                font-weight: 200;
                &:hover{
                    text-decoration: underline;
                    font-weight: 500;
                }
            }
        }
    }
}