@import "../global.scss";
.about-container {
    background-color: $mainColor;
    display: flex;
    flex-direction: column;
    color: $yellowColor;

    .left{
        flex: 0.4;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2em;
        @include tablet{
            align-items: center;
            font-size: 1em;
        }

        width: 100%;
        height: 100%;
        
        h3{
           text-decoration: underline;
           font-size: 1.5em;
         
        }
        ul{
            
           
            li{
                padding: .25em;
                list-style:square;
            }
        }
    }

    .right{
        flex: 0.6;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet{
            align-items: center;
            font-size: 1em;
        }

        width: 100%;
        height: 100%;
        h3{
            text-decoration: underline;
            font-size: 1.5em;
            
        }
        p{
            span{
                font-size: 20px;
                font-weight: 600;
                text-decoration: underline;
            }
            @include tablet{
                padding: 1em;
            }
            @include desktop{
                width: 60%;
            }
        }
        
    }
}